import { above } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SelectDropdown from 'theme/components/SelectDropdown';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const SearchTextInput = styled('input')`
    text-align: left;
    height: 56px;
    border: 1px solid ${colors.grey};
    margin: 0;
    width: 100%;
    border-radius: 4px;
    box-shadow: none;
    transition: border 200ms ease-in-out;

    &:hover {
        border: 1px solid ${colors.black};
    }

    &:focus {
        background: ${colors.white};
        box-shadow: none;
    }

    &::-ms-clear {
        display: none;
    }

    &::-webkit-input-placeholder {
        opacity: 1;
    }

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

const TextInputWrapper = styled('div')`
    width: 50%;
    ${above.lg} {
        width: 221px;
    }
`;

const SelectWrapper = styled('div')`
    position: relative;
    width: 50%;
    ${above.lg} {
        width: 221px;
    }
`;

const FilterInput = ({ type, operator, options, placeholder, filter, callback, keyValues, id, handleEnter }) => {
    const [[firstValue, secondValue], setValues] = useState([null, null]);
    const stringOp = operator === 'equal_string';

    useEffect(() => {
        if (keyValues.length > 1) {
            const firstKeyValue = keyValues[0] ? keyValues[0] : '';
            const secondKeyValue = keyValues[1] ? keyValues[1] : '';
            setValues([firstKeyValue, secondKeyValue]);
        }
    }, [keyValues]);

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            handleEnter();
        }
    };

    if (!filter) {
        return null;
    }

    return type === 'select' ? (
        <SelectWrapper>
            <SelectDropdown
                isHover
                setMaxHeight="150px"
                hoverColor={colors.black}
                callback={(value) => {
                    callback(filter, value, operator);
                }}
                placeholder={id === 1 ? firstValue || placeholder : secondValue || placeholder}
                items={options.map(({ value, text }) => ({
                    label: text,
                    value,
                }))}
            />
        </SelectWrapper>
    ) : (
        <TextInputWrapper>
            <SearchTextInput
                defaultValue={firstValue}
                p={['0 24px']}
                bg={colors.white}
                type={stringOp ? 'text' : 'number'}
                placeholder={id === 0 ? firstValue || placeholder : secondValue || placeholder}
                autoComplete="off"
                onChange={({ target }) => callback(filter, target.value, operator)}
                onKeyDown={handleKeyPress}
            />
        </TextInputWrapper>
    );
};

FilterInput.propTypes = {
    callback: PropTypes.func,
    filter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleEnter: PropTypes.func,
    id: PropTypes.number.isRequired,
    keyValues: PropTypes.array,
    operator: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
    placeholder: PropTypes.string,
    type: PropTypes.string,
};

FilterInput.defaultProps = {
    callback: null,
    handleEnter: null,
    keyValues: null,
    operator: 'equal_string',
    options: [],
    placeholder: '',
    type: '',
};

export default FilterInput;
