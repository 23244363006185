import styled from 'theme/libs/styled';
import PropTypes from 'prop-types';
import colors from 'theme/config/colors';

const H2 = styled('h2')();

H2.propTypes = {
    color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    fontFamily: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    lineHeight: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    textTransform: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

H2.defaultProps = {
    color: colors.darkGrey,
    fontFamily: 'Inter',
    fontSize: '32px',
    lineHeight: '1em',
};

export default H2;
