import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import styled from 'theme/libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: (prop) => ['timingFunction', 'duration', 'showHeight'].indexOf(prop) === -1,
})`
    max-height: ${({ showHeight }) => showHeight};
    overflow: hidden;
    transition: ${({ duration, timingFunction }) => duration > 0 && `max-height ${duration}ms ${timingFunction}`};
`;

class AnimateHeight extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        // Transition duration in milliseconds.
        duration: PropTypes.number,
        id: PropTypes.string,
        isFull: PropTypes.bool,
        showHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        timingFunction: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: null,
        duration: 200,
        id: null,
        isFull: false,
        showHeight: 0,
        timingFunction: 'cubic-bezier(0.46, 0.88, 0.7, 0.83)',
    };

    // @todo: Find a way to animate when maxHeight shrink
    static updateHeight(props, node) {
        const { isFull, maxHeight } = props;
        if (node) {
            const { scrollHeight } = node;

            let height = scrollHeight;

            if (maxHeight) {
                height = scrollHeight > parseInt(maxHeight, 10) ? maxHeight : scrollHeight;
            }

            let value = null;

            if (isFull) {
                value = Number.isNaN(height) ? height : `${height}px`;
            }

            node.style.maxHeight = value;
        }
    }

    element = createRef();

    componentDidMount() {
        AnimateHeight.updateHeight(this.props, this.element.current);
    }

    componentDidUpdate(prevProps) {
        const { isFull } = this.props;
        const node = this.element.current;

        if (prevProps.isFull !== isFull) {
            AnimateHeight.updateHeight(this.props, node);
        }
    }

    render() {
        const { children, duration, timingFunction, className, id, showHeight } = this.props;

        return (
            <Wrapper
                id={id}
                ref={this.element}
                duration={duration}
                timingFunction={timingFunction}
                className={className}
                showHeight={showHeight}
            >
                {children}
            </Wrapper>
        );
    }
}

export default AnimateHeight;
