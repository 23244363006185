import Image from 'core-web/components/Image';
import useOutsideClick from 'core-web/hooks/useOutsideClick';
import { stormSrc } from 'core-web/libs/image';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'theme/components/AnimateHeight';
import ChevronIcon from 'theme/components/icons/ChevronIcon';
import colors from 'theme/config/colors';
import { PLACEHOLDER } from 'theme/config/constants';
import styled from 'theme/libs/styled';

/* General */
const excludedProps = ['isOpen', 'isDisabled', 'hoverColor', 'isHover', 'setMaxHeight'];

const SelectBody = styled('div')`
    position: relative;
    height: 56px;
    overflow: visible;
`;

const SelectItem = styled('button')`
    position: relative;
    text-align: left;
    height: 56px;
    padding: 0px 20px;
    width: 100%;
`;

const SelectAfter = styled('div')`
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`;

/* Selected Item */
const SelectedItem = styled(SelectItem, {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${colors.grey};
    border-width: ${(props) => (props.isOpen ? '1px 1px 0' : '1px')};
    border-radius: ${(props) => (props.isOpen ? '4px 4px 0 0' : '4px')};
    font-weight: 700;
    transition: border-color 200ms ease-out;
    ${(props) =>
        props.isHover &&
        `
        &:hover {
            border-color: ${props.hoverColor};
        }
    `}
`;

/* List */
const SelectList = styled('div', {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    ${({ setMaxHeight }) =>
        setMaxHeight &&
        `
        max-height: ${setMaxHeight};
        overflow-y: auto;
    `};

    position: relative;
    z-index: 1;
    background: ${colors.white};
    border-style: solid;
    border-color: #ccc;
    border-width: 0 1px 1px;
    border-radius: 0 0 4px 4px;
`;
const SelectListItem = styled(SelectItem, {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #ccc;

    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'all')};

    &:hover {
        background-color: ${colors.lightGrey};
    }
`;

const SelectListItemLabel = styled('div')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
`;

const SelectDropdown = (props) => {
    const {
        items,
        placeholder,
        selected,
        isHover,
        hoverColor,
        duration,
        timingFunction,
        showHeight,
        showImage,
        setMaxHeight,
        mobileFontSize,
        name,
        callback,
    } = props;

    const ref = useRef();
    const [isOpen, setOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState(null);

    useOutsideClick(ref, () => {
        setOpen(false);
    });

    const itemChange = (value, index) => {
        setOpen(false);
        callback(value, index);
        setCurrentValue(value);
    };

    useEffect(() => {
        if (selected !== currentValue) {
            setCurrentValue(selected || null);
        }
    }, [selected]);

    const selectedItem = currentValue && items.find((item) => item.value === currentValue);
    const selectedImage = selectedItem?.image;
    const imgPlaceholder = PLACEHOLDER;

    let selectedItemLabel = placeholder;

    if (currentValue) {
        const nameString = `${name}: `;
        selectedItemLabel = `${name ? nameString : ''}${currentValue?.label || currentValue?.value || currentValue}`;
    }

    return (
        <SelectBody ref={ref}>
            <SelectedItem
                isHover={isHover}
                hoverColor={hoverColor}
                isOpen={isOpen}
                fontSize={mobileFontSize ? ['12px', null, '16px'] : ['16px']}
                lineHeight={mobileFontSize ? ['14px', null, '18px'] : ['18px']}
                onClick={() => setOpen(!isOpen)}
            >
                {showImage && currentValue && (
                    <Image
                        src={{
                            url: selectedImage
                                ? stormSrc(selectedImage, { m: 'pad', f: 'jpg', w: 32, h: 32 })
                                : imgPlaceholder,
                            width: 72,
                        }}
                        placeholder={placeholder}
                        loading={null}
                        objectFit="contain"
                        height="auto"
                        maxHeight="32px"
                        width="32px"
                        m="0 12px 0 4px"
                    />
                )}
                {/* <SelectListItemLabel> */}
                {selectedItemLabel}
                {/* </SelectListItemLabel> */}
                <SelectAfter right={mobileFontSize ? '16px' : '24px'}>
                    <ChevronIcon direction={isOpen ? 'up' : 'down'} strokeWidth="1" ml="16px" />
                </SelectAfter>
            </SelectedItem>
            <AnimateHeight isFull={isOpen} duration={duration} timingFunction={timingFunction} showHeight={showHeight}>
                <SelectList isOpen={isOpen} setMaxHeight={setMaxHeight}>
                    {items.map((item, index) => (
                        <SelectListItem
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            isDisabled={item.disabled}
                            key={index}
                            onClick={() => itemChange(item.value, index)}
                        >
                            {showImage && (
                                <Image
                                    src={{
                                        url: item?.image
                                            ? stormSrc(`${item.image}`, {
                                                  m: 'pad',
                                                  f: 'jpg',
                                                  w: 32,
                                                  h: 32,
                                              })
                                            : imgPlaceholder,
                                        width: 72,
                                    }}
                                    placeholder={imgPlaceholder}
                                    objectFit="contain"
                                    height="auto"
                                    maxHeight="32px"
                                    width="32px"
                                    m="0 12px 0 4px"
                                />
                            )}
                            <SelectListItemLabel color={item.disabled ? colors.textGrey : 'inherit'}>
                                {item.label}
                            </SelectListItemLabel>
                            {item.after && (
                                <SelectAfter right={mobileFontSize ? '16px' : '24px'}>{item.after()}</SelectAfter>
                            )}
                        </SelectListItem>
                    ))}
                </SelectList>
            </AnimateHeight>
        </SelectBody>
    );
};

SelectDropdown.defaultProps = {
    duration: 200,
    hoverColor: colors.black,
    isHover: false,
    mobileFontSize: false,
    name: null,
    selected: null,
    setMaxHeight: '',
    showHeight: 0,
    showImage: false,
    timingFunction: 'cubic-bezier(0.46, 0.88, 0.7, 0.83)',
};

SelectDropdown.propTypes = {
    callback: PropTypes.func.isRequired,
    duration: PropTypes.number,
    hoverColor: PropTypes.string,
    isHover: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any,
            disabled: PropTypes.bool,
            after: PropTypes.func,
        }),
    ).isRequired,
    mobileFontSize: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    selected: PropTypes.any,
    setMaxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showHeight: PropTypes.number,
    showImage: PropTypes.bool,
    timingFunction: PropTypes.string,
};

export default SelectDropdown;
