import { above } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import H1 from 'theme/components/base/headings/H1';
import H2 from 'theme/components/base/headings/H2';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import FilterInputs from 'theme/components/productguide/FilterInputs';
import LinkCard from 'theme/components/productguide/LinkCard';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Wrapper = styled('div', { shouldForwardProp: (prop) => ['isOnPage'].indexOf(prop) === -1 })`
    display: flex;
    margin-top: 4px;

    ${above.md} {
        border: ${({ isOnPage }) => isOnPage && `1px solid ${colors.grey}`};
        margin-top: 0;
    }

    ${({ isOnPage }) =>
        isOnPage
            ? `
            flex-direction: column;
            justify-content: center;
            ${above.lg} {
                flex-direction: row;
                justify-content: flex-start;
                //overrides the aspect wrappers overflow hidden, needs to show the dropdown
                overflow: visible;
            }
        `
            : `
            flex-direction: column;
            justify-content: center;
        `};
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    text-decoration: none;
`;

const Head = styled('div')`
    position: absolute;
    top: 8px;
    left: 8px;
    right: 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    ${above.lg} {
        top: 12px;
        left: 12px;
    }
`;

const Div = styled('div')();

const ManufacturerLogo = styled('img')`
    max-height: 16px;
    object-fit: cover;
`;

const Content = ({ data, isH1Heading, t, isOnPage, history, isInProductGrid, isWide, keyValues }) => {
    const [filter, setFilter] = useState({});

    const callback = (key, value, operator) => {
        setFilter({ ...filter, [key]: { key, value, operator } });
    };

    const setQueryString = (linkUrl) => {
        const { category_to_filter: categoryToFilter, manufacturer } = data;
        const queryObj = [];

        if (categoryToFilter) {
            queryObj.push([`categories.code:${categoryToFilter}`]);
        }
        if (manufacturer) {
            queryObj.push([`manufacturer.code:${manufacturer}`]);
        }

        const filterValues = Object.values(filter)
            .filter((filter) => filter.value.length)
            .map(({ key, operator, value }) => [`"parametrics.${key}.value.value"${t(operator)}${value}`]);

        if (filterValues.length) {
            queryObj.push(...filterValues);
        }

        if (queryObj.length) {
            const queryUrl = encodeURIComponent(JSON.stringify(queryObj));
            history.push(`${linkUrl || ''}?${queryUrl}`);
        }
    };

    const { image, link, loading, filter: parametrics } = data;
    const heading = data.text;
    const { text: linkText, url: linkUrl } = link || {};
    const { url: imageUrl, title: imageTitle, meta: imageMeta } = image || {};

    return !isWide && !isOnPage ? (
        <LinkCard link={link} heading={heading} isH1Heading={isH1Heading} />
    ) : (
        <Wrapper backgroundColor={colors.white} isOnPage={isOnPage} p="16px">
            <Head>
                {imageUrl && (
                    <ManufacturerLogo
                        src={`${imageUrl}?mode=max&h=16`}
                        alt={imageTitle || imageMeta.name}
                        loading={loading}
                    />
                )}
            </Head>
            {heading &&
                (isH1Heading ? (
                    <H1
                        as={isInProductGrid ? 'p' : H1}
                        textAlign={isOnPage && ['center', null, 'left']}
                        fontWeight="700"
                        color={colors.black}
                        maxWidth={isOnPage ? 'unset' : ['400px', '500px', '550px']}
                        lineHeight={isOnPage ? '30px' : ['30px', null, '40px']}
                        fontSize={isOnPage ? '24px' : ['24px', null, '32px']}
                        zIndex="10"
                        mb={['24px', null, isOnPage ? '0px' : '32px']}
                        mr={isOnPage ? [null, null, 'auto'] : '0px'}
                        pr={isOnPage && '16px'}
                    >
                        {heading}
                    </H1>
                ) : (
                    <H2
                        as={isInProductGrid ? 'p' : H2}
                        textAlign={isOnPage && ['center', null, 'left']}
                        fontWeight="700"
                        color={colors.black}
                        maxWidth={isOnPage ? 'unset' : ['400px', '500px', '550px']}
                        lineHeight={isOnPage ? '30px' : ['30px', null, '40px']}
                        fontSize={isOnPage ? '24px' : ['24px', null, '32px']}
                        zIndex="10"
                        mb={['24px', null, isOnPage ? '0px' : '32px']}
                        mr={isOnPage ? [null, null, 'auto'] : '0px'}
                        pr={isOnPage && '16px'}
                    >
                        {heading}
                    </H2>
                ))}
            {filter && (
                <Div mb={['24px', null, isOnPage ? '0px' : '32px']} width={['100%', null, 'auto']}>
                    <FilterInputs
                        isOnPage
                        keyValues={keyValues}
                        filter={parametrics}
                        callback={callback}
                        handleEnter={() => setQueryString(linkUrl)}
                    />
                </Div>
            )}

            <RoundedButton
                theme="green"
                fontSize={['12px', null, '14px']}
                height="fit-content"
                padding="16px"
                whiteSpace="nowrap"
                ml={isOnPage ? [null, null, '34px', '56px'] : '0px'}
                onClick={() => setQueryString(linkUrl)}
            >
                {linkText || t('search')}
            </RoundedButton>
        </Wrapper>
    );
};

Content.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isH1Heading: PropTypes.bool.isRequired,
    isInProductGrid: PropTypes.bool,
    isOnPage: PropTypes.bool,
    isWide: PropTypes.bool,
    keyValues: PropTypes.array,
    t: PropTypes.func,
};

Content.defaultProps = {
    isInProductGrid: false,
    isOnPage: false,
    isWide: false,
    keyValues: [],
    t: null,
};

export default withRouter(Content);
